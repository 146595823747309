import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import HeroBanner from 'components/heroBanner'
import Intro from 'components/intro'
import CopyBlock from 'components/copyBlock'
import CopySplit from 'components/copySplit'
import Office from 'components/office'
import { Social } from 'components/social'
import ContactForm from 'components/contactForm'
import PartnerCarousel from 'components/partnerCarousel'
import Awards from 'components/awards'
import { graphql } from 'gatsby'

const ContactPage = ({ data, location }) => (
  <Layout location={location}>
    <Helmet>
      <title>{data.contactJson.title}</title>
      <meta name="description" content={data.contactJson.description} />
    </Helmet>
    <HeroBanner hero={data.contactJson.hero} />
    <Intro intro={data.contactJson.intro} />

    <CopyBlock>
      <CopySplit>
        <h3>Email</h3>
        <p>
          <a href="mailto:info@pcwsolutions.co.uk">info@pcwsolutions.co.uk</a>
        </p>
      </CopySplit>
      <CopySplit>
        <h3>Sales / Support</h3>
        <p>
          <a href="tel:00442070525011">0207 0525 011</a>
        </p>
      </CopySplit>
      <CopySplit>
        <h3>Offices</h3>
        <div>
          {data.contactJson.offices.map((office, i) => (
            <Office key={i} {...office} />
          ))}
        </div>
      </CopySplit>
      <CopySplit>
        <h3>International</h3>
        <div>
          {data.contactJson.internationalOffices.map((office, i) => (
            <Office key={i} {...office} />
          ))}
        </div>
      </CopySplit>
      <CopySplit>
        <h3>Connect</h3>
        <Social />
      </CopySplit>
    </CopyBlock>

    <CopyBlock bgTint>
      <ContactForm heading="Submit a Form" intro="Complete the form below for sales, support or to raise a ticket." />
    </CopyBlock>

    <PartnerCarousel />
    <Awards />
  </Layout>
)

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default ContactPage

export const query = graphql`
  query ContactPageQuery {
    contactJson {
      title
      description
      hero {
        heading
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
      intro {
        heading
        copy
      }
      offices {
        officeName
        address
        telephone
        telHref
      }
      internationalOffices {
        officeName
        address
        telephone
        telHref
      }
    }
  }
`
