import React from 'react'
import PropTypes from 'prop-types'
import { Container, Address, Link } from './office.css'

const Office = ({ officeName, address, telephone, telHref }) => (
  <Container>
    <h4>{officeName}</h4>
    <Address dangerouslySetInnerHTML={{ __html: address }} />
    <Link href={`tel: ${telHref}`}>{telephone}</Link>
  </Container>
)

Office.propTypes = {
  officeName: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  telephone: PropTypes.string.isRequired,
}

export default Office
